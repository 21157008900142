export default [
  {
    title: "Pdf",
    route: "pdf",
    icon: "FileIcon",
    user_types: [],
  },
  // {
  //   title: "User",
  //   route: "user",
  //   icon: "UserIcon",
  //   user_types: [],
  // },
  {
    title: "Client",
    route: "client",
    icon: "AirplayIcon",
    user_types: [],
  },
];
